export enum PagesType {
  DETAILS_PAGE_ENABLED = 1,
  DETAILS_PAGE_DISABLED = 2,
}

export enum EventsList {
  PAST = 'past',
  UPCOMING = 'upcoming',
}

export enum Tabs {
  UPCOMING = 0,
  PAST = 1,
}

export enum Routes {
  DEFAULT = 'DEFAULT',
  ORDER_LIST = 'ORDER_LIST',
  ORDER = 'ORDER',
}

export enum MembersSettings {
  noUpcomingEventsLinkPageId = 'noUpcomingEventsLinkPageId',
}

export enum MembersTexts {
  pageTitle = 'pageTitle',
  description = 'description',
  upcomingEventsTab = 'upcomingEventsTab',
  pastEventsTab = 'pastEventsTab',
  noUpcomingEventsLink = 'noUpcomingEventsLink',
}

export enum MembersSettingsEvents {
  SETTINGS = 'SETTINGS',
  OPEN_DETAILS = 'OPEN_DETAILS',
  OPEN_NO_EVENTS = 'OPEN_NO_EVENTS',
  OPEN_DEFAULT = 'OPEN_DEFAULT',
}

export enum RegistrationErrorKey {
  RSVP_CLOSED = 'RSVP_CLOSED',
  GUEST_LIMIT_REACHED = 'GUEST_LIMIT_REACHED',
  WAITING_LIST_UNAVAILABLE = 'WAITING_LIST_UNAVAILABLE',
  GUEST_ALREADY_REGISTERED = 'GUEST_ALREADY_REGISTERED',
  MEMBER_ALREADY_REGISTERED = 'MEMBER_ALREADY_REGISTERED',
  UNEXPECTED_RSVP_RESPONSE = 'UNEXPECTED_RSVP_RESPONSE',
  UNKNOWN_RSVP_ERROR = 'UNKNOWN_RSVP_ERROR',
  INVALID_POLICY_AGREEMENT_TOKEN = 'INVALID_POLICY_AGREEMENT_TOKEN',
}

export enum ReservationState {
  SUCCESS = 'success',
  ERROR = 'error',
  PENDING = 'pending',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
}

export enum TextSection {
  RIBBON = 'ribbon',
  RSVP_CLOSED = 'rsvp-closed',
  RSVP = 'rsvp',
  TODAY = 'today',
  NONE = '',
}

export enum DesignSection {
  BUTTONS = 'buttons',
  RIBBONS = 'ribbons',
  EVENT_DETAILS_POPUP = 'eventDetailsPopup',
  EVENT_LIST_POPUP = 'eventListPopup',
  NONE = '',
}

export enum UserRole {
  ADMIN = 'Admin',
  MEMBER = 'Member',
  Visitor = 'Visitor',
}

export enum SeatInfoType {
  ROW = 'ROW',
  AREA = 'AREA',
  TABLE = 'TABLE',
}
